html:has(div.about-me-header-section) { overflow-y: hidden;}

body {
    font-family:'Open Sans';
    font-style: normal;
}

.portfolio-link, .portfolio-link-image{
    color: #9d9ea1;
    transition: 0.5s;
    cursor: pointer;
}

.portfolio-link{
    transition: 0s;
}

.portfolio-link-image{
    color: #A3C6C4 ;
}

.external-link-icon{
    transition: 0.5s;
}

.external-link-icon:hover{
    color: #ffffff !important;
}

.portfolio-link:hover{
    text-decoration: underline white;
}

.skills-list li::before{
    content: "✔";
	position: relative;
	right: 10px;
}

.portfolio-link-image:hover{
    filter:brightness(3);
}

.shadow-glow{
    color: #dfe7e9;
    background-color: #232d2f;
    transition: 0.1s;
    box-shadow: 0px 0px 11px -1px  rgba(0,0,0,0.25);
}

.shadow-glow:hover {
    transition: 0.5s;
    box-shadow: 0px 0px 11px -1px  rgba(255,255,255,0.75);
}

.link-info{
    color: #A3C6C4 !important;
    text-decoration: underline #A3C6C4 !important;
    transition: 0.1s;
}

.link-info:hover{
    transition: 0.3s;
    text-decoration: underline #c3ecea !important;
    color: #c3ecea !important;
}

.bg-dark {
    background-color: #344648 !important;
}

.btn-info {
    border: none;
    background-color: #dfe7e9 !important;
    transition: 0.5s;
}

.btn-info:hover{
    background-color: #b5bcbd !important;
}

.btn-primary {
    border: none;
    color: #344648 !important;
    background-color: #A3C6C4 !important;
    transition: 0.5s;
}

.btn-primary:hover{
    background-color: #7f999d !important;
}

.text-primary {
    color: #A3C6C4 !important;
}

.text-secondary {
    color: #E0E7E9 !important;
}

.text-white {
    color: #E0E7E9 !important;
}

.text-info {
    color: #A3C6C4 !important;
}

.text-success {
    color: #8dc48f !important;
}

.text-danger {
    color: #e6a2a2 !important;
}

.btn-success {
    background-color: #8dc48f !important;
    transition: 0.1s;
}

.btn-success:hover{
    transition: 0.3s;
    background-color: #75a377 !important;
}

.cursor-pointer{
    cursor:pointer
}

.about-me-header-section {
    background-color:#232d2f;
    background-image: linear-gradient(0.25turn, #222d2b, #232d2f, #222d2b);
}


@media (min-device-width: 1024px){
    .about-me-header-section {
        padding-inline: 160px;
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: auto auto;
        width: 100%;
    }

    .about-me-skills-grid {
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 10px;
        justify-content: center;
    }
}

@media (max-device-width: 1024px){
    .about-me-header-section {
        padding: 50px;
        display: grid;
        justify-content:space-evenly;
        grid-template-columns: auto;
        width: 100%;
        text-align: center;
    }

    .about-me-skills-grid {
        overflow-y: hidden;
        display: grid;
        grid-template-columns: auto;
        justify-content: space-evenly;
        row-gap: 10px;
    }
}

@media (min-device-width: 1250px)
{
    .overlay-box-grid{
        display: grid;
        grid-template-columns: auto auto auto; 
        row-gap: 10px; 
        column-gap: 10px; 
        justify-content: center;
    }
}

@media (max-device-width: 1250px)
{
    .overlay-box-grid{
        display: grid;
        grid-template-columns: auto auto; 
        row-gap: 10px; 
        column-gap: 10px; 
        justify-content: center;
    }
}

@media (max-device-width: 850px)
{
    .overlay-box-grid{
        display: grid;
        grid-template-columns: auto; 
        row-gap: 30px; 
        justify-content: center;
    }
}


@media (max-device-width: 768px)
{
    .sections-dropdown{
        display: block;
    }

    .laptop-nav-item {
        display: none;
    }

    .contact-form-name-and-email-fields{
        display:grid;
        grid-template-columns: auto; 
        row-gap: 10px;
    }
}

@media (min-device-width: 768px)
{
    .sections-dropdown{
        display: none;
    }

    .laptop-nav-item{
        display: inline
    }

    .contact-form-name-and-email-fields{
        display:grid;
        grid-template-columns: auto auto; 
        column-gap: 20px;
    }
}

/* A bit above tablet */
@media (min-device-width: 800px)
{
    .resume-img{
        width: calc(1545px/2);
        height: calc(2000px/2); 
    }
}

@media (max-device-width: 800px)
{
    .resume-img{
        width: calc(1545px/2.5);
        height: calc(2000px/2.5);
    }
}

@media (max-device-width: 626px)
{
    .resume-img{
        width: calc(1545px/4);
        height: calc(2000px/4);
    }
}

@media (max-device-width: 417px)
{
    .resume-img{
        width: calc(1545px/5);
        height: calc(2000px/5);
    }
}

.dropright .dropdown-menu{
    left:auto!important;
    right:-110px!important;
}

.overlay-front {
    background-color: #344d3c;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s;
}

.overlay-front:hover{
    opacity: 1;
}

.overlay-back{
    opacity: 1;
    transition: opacity 0.5s;
}

.overlay-back:hover{
    opacity: 0;
}